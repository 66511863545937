.ant-message-custom-content3333 {
  font-size: 18px; /* 调整字体大小 */
  line-height: 2; /* 可能需要调整行高以保持良好的阅读体验 */
  padding: 80px; /* 根据需要调整内边距 */
}

#root {
  box-sizing: border-box;
  /*font-family: Source Han Sans CN;*/
  color: #414141;
}
.content-padding {
  padding: 100px 140px;
}
/*标头中英文样式*/
.about-intro-1 {
  font-size: 49px;
  font-weight: bold;
  color: #414141;
  line-height: 70px;
}
.about-intro-2 {
  font-size: 21px;
  font-weight: 400;
  color: #fbc400;
  line-height: 25px;
}
/* 后台图片管理的css */
.delete-icon:hover {
  transform: scale(1.2);
}
.pic-manage-head {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid black;
  .brand-head-button {
    margin: 10px;
  }
}
.over-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*箭头样式*/
.about-fate-arrow-left,
.about-fate-arrow-right {
  cursor: pointer;
  font-size: 45px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fbc400;
  color: white;
  z-index: 3;
}
.about-fate-arrow-right {
  background-color: #fbc400;
  color: white;
}
//PC端主标题样式
.normal_topic {
  font-size: 48px;
  color: #414141;
  line-height: 60px;
  letter-spacing: 5px;
  font-weight: bold;
}
//PC端副标题样式
.normal_vice_topic {
  margin-top: 2px;
  font-size: 20px;
  color: #fbc400;
  line-height: 25px;
  font-weight: 500;
}
.normal_index_topic {
  font-size: 40px;
  color: #414141;
  line-height: 40px;
  letter-spacing: 5px;
  font-weight: bold;
}
.normal_index_vice_topic {
  font-size: 18px;
  color: #fbc400;
  line-height: 28px;
  font-weight: 500;
}
.normarl_index_third_topic {
  font-size: 30px;
  color: #414141;
  line-height: 48px;
  letter-spacing: 2px;
  font-weight: bold;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  body {
    font-size: 50px;
  }
}





body {
  transform: scale(1);
}

/*@media screen and (min-width: 1025px) and (max-width: 1920px) {*/
@media screen and (min-width: 1125px) and (max-width: 1820px) {
  .transformbox {
    transform: scale(0.75);
    transform-origin: center top;
  }
}